/**
 * INTR Style Guide Header React Only for responsive pages
 * --------------------------------
*/
/**
 * Header React styles
 * --------------------------------
*/

@import '../../../skin/css/sg/variables/variables_less';
@import '../../../skin/css/sg/mixins_less';
@import '../../styles/buttons';

.header {
    min-width:         @min_width;
    height:            @col_top_height;
    background-color:  @green1;
    background-image:  none;
    position:          fixed;
    width:             100%;
    top:               0;
    left:              0;
    z-index:           98;
    transition:        top .3s ease-out;
    -webkit-transform: translateZ(0px);
    -moz-transform:    translateZ(0px);
    -o-transform:      translateZ(0px);
    transform:         translateZ(0px);

    @media only screen and (max-width: (@big_width)) {
        height:     @col_top_height_mobile;
        text-align: center;
    }

    @media only screen and (max-width: (@mobile)) {
        height:     @col_top_height_mobile;
        text-align: center;
    }

    &Container {
        box-sizing: border-box;
        min-width:  @min_width;
        max-width:  @max_width + 40px;
        margin:     0 auto;
        width:      100%;
        display:    flex;

        @media only screen and (max-width: (@desktop)) {
            padding: 0 16px;
        }
    }

    &Title {
        color:          #fff;
        font-size:      20px;
        font-weight:    600;
        letter-spacing: 0.3px;
        line-height:    30px;
        text-align:     center;

        @media only screen and (max-width: (@max_width)) {
            display: none;
        }
    }

    &Admin {
        .headerContainer {
            @media only screen and (max-width: (@max_width)) {
                justify-content: center;
            }
        }

        &Sandwich {
            @media only screen and (max-width: (@max_width)) {
                display: flex !important;
                top:     4px !important;
            }
        }

        &Logo {
            @media only screen and (max-width: (@max_width)) {
                margin: 0 !important;
            }
        }
    }

    &Over {
        z-index: 100;
    }
}

.linkHeader {
    transition:      all 0.2s ease-in-out;
    cursor:          pointer;
    color:           #ffffff !important;
    font-weight:     600;
    text-decoration: none;
    letter-spacing:  0.01rem;
    font-size:       14px;
    border:          0;
    background:      transparent;
    outline:         transparent;
    padding:         0 0 0 3px;
    height:          auto;
    width:           auto;


    &:active {
        color: #ffffff;

        @media only screen and (max-width: (@big_width)) {
            color:            @gray2;
            border-color:     @gray3;
            background-color: @gray3;
        }
    }

    &:focus,
    &:hover {
        color:           #ffffff;
        text-decoration: none;

        @media only screen and (max-width: (@big_width)) {
            border-color:     @gray5;
            background-color: @gray5;
        }
    }

    @media only screen and (max-width: (@big_width)) {
        width:            50%;
        height:           36px;
        line-height:      36px;
        text-align:       center;
        border-color:     @gray4;
        background-color: @gray4;
        text-decoration:  none;
        padding:          0 20px;
        border-radius:    3px;
    }

    &Admin {
        width:            50%;
        height:           36px;
        line-height:      36px;
        text-align:       center;
        border-color:     @gray4;
        background-color: @gray4;
        text-decoration:  none;
        padding:          0 20px;
        border-radius:    3px;
    }
}

.linkAccount {
    margin-right: 15px;
}

.linkToCart {
    line-height: 70px;
    margin-left: auto;
    font-size:   16px;

    @media only screen and (max-width: (@big_width)) {
        display: none;
    }
}

.btnHeader {
    padding:        0 0.9rem;
    letter-spacing: 0.015rem;
    display:        inline-block;
    margin:         0 20px 0 0;
    font-weight:    600;
    min-width:      98px;
    width:          auto;

    @media only screen and (max-width: 1110px) {
        margin:    0 1.1rem 0 0;
        min-width: auto;
    }

    @media only screen and (max-width: (@big_width)) {
        width: 50%;
    }

    &Admin {
        width:        50%;
        order:        0 !important;
        margin-right: 1.1rem !important;
    }

    &AdminDesc {
        max-width:  113px;
        margin:     0 auto;
        align-self: center;

        @media only screen and (max-width: (@max_width)) {
            display: none;
        }
    }
}

.overlay,
.cartOverlay {
    flex-grow:  1;
    z-index:    1;
    position:   fixed;
    top:        0;
    left:       0;
    right:      0;
    height:     100vh;
    background: rgba(0, 0, 0, .4);
    display:    none;
    animation:  fadeIn .2s ease-in;

    &.show {
        display:    block;
        overflow-y: auto;
    }
}

.overlayOpacity {
    background: transparent;
    opacity:    0;
}

.sidebar {
    background:                 #38414b;
    width:                      auto;
    max-width:                  410px;
    height:                     100vh;
    min-height:                 240px;
    position:                   fixed;
    top:                        0;
    right:                      50px;
    bottom:                     0;
    left:                       0;
    z-index:                    12;
    -webkit-overflow-scrolling: touch;
    transition:                 all .15s ease-out;
    animation:                  toRight .2s ease-in;
    display:                    none;
    overflow-x:                 hidden;
    overflow-y:                 auto;

    &.show {
        display: block;
    }

    &Menu {
        background: #ffffff;
        box-shadow: 0 0 17px 1px rgba(35, 45, 55, 0.15);
        padding:    30px 0;
        width:      320px;
        height:     100vh;
        min-height: 240px;
        right:      auto;
    }

    .userInfoButtons {
        display: flex;
        padding: 16px;

        .btnHeader {
            order:  2;
            margin: 0 0 0 1.1rem;
        }

        .userInfoBtn {
            width: 50%;
        }
    }

    .userInfo {
        &Data {
            position:        relative;
            height:          140px;
            overflow:        hidden;
            background:      @gray1;
            display:         flex;
            flex-direction:  column;
            align-items:     center;
            justify-content: space-between;
            box-sizing:      border-box;
            padding:         24px 0 18px;
        }

        &Name {
            color:          #ffffff;
            font-size:      16px;
            font-weight:    600;
            line-height:    1.5;
            letter-spacing: 0.16px;
            text-align:     center;
            margin-top:     auto;
            text-transform: capitalize;

            @media only screen and (max-width: (@tablet_horizontal)) {
                width:         100%;
                white-space:   nowrap;
                overflow:      hidden;
                text-overflow: ellipsis;
                box-sizing:    border-box;
                padding:       0 16px;
            }

            &Initials {
                display:         flex;
                justify-content: center;
                align-items:     center;
                width:           60px;
                height:          60px;
                background:      @gray4;
                color:           @gray6;
                border-radius:   50%;
                font-size:       30px;
                font-weight:     400;
                line-height:     1;
                text-transform:  uppercase;
                letter-spacing:  0.69px;
            }
        }

        &Avatar {
            background-size:     60px;
            background-position: center top;
            background-repeat:   no-repeat;
            border-radius:       50%;
            width:               60px;
            height:              60px;
        }
    }
}

.authpanelActions {
    display:     flex;
    align-items: center;
    margin-left: auto;

    @media only screen and (max-width: (@big_width)) {
        position: absolute;
        height:   60px;
        right:    16px;
        top:      0;
    }

    &Inner {
        @media only screen and (max-width: (@big_width)) {
            display: none;
        }
    }
}

.noscroll {
    overflow: hidden;
}

@keyframes toRight {
    0% {
        transform: translateX(-100%)
    }
    100% {
        transform: translateX(0)
    }
}

@keyframes fadeIn {
    from {
        opacity: 0
    }
    to {
        opacity: 1
    }
}


// admin header
.adminLogoLink {
    margin:      2px 4.175rem 0 0;
    height:      @col_top_height;
    line-height: @col_top_height;
    display:     inline-block;
    width:       12.21rem;
    box-sizing:  border-box;

    @media only screen and (max-width: (@max_width - @infelicity)) {
        margin: 0 2rem 0 0;
    }

    @media only screen and (max-width: (@max_width - @infelicity*2)) {
        margin: 0 1rem 0 0;
    }

    @media only screen and (max-width: (@desktop)) {
        width: 11rem;
    }

    .logo {
        box-sizing:     border-box;
        position:       relative;
        z-index:        1;
        vertical-align: text-bottom;
        height:         @col_top_height;
        line-height:    @col_top_height;
        width:          100%;
        display:        block;
        top:            -2px;
        padding-bottom: 6px;

        @media only screen and (max-width: (@tablet_horizontal)) {
            height:         60px;
            padding-bottom: 4px;
            top:            0;
        }
    }
}

.Coach {

    &Nav {
        display:         flex;
        justify-content: space-between;
        align-items:     center;
    }
}

.adminDashboardBtn {
    position:  absolute;
    top:       13px;
    left:      10px;
    z-index:   2;
    padding:   0 !important;
    height:    40px !important;
    font-size: 14px !important;
    min-width: 100px !important;

    @media screen and (max-width: (@max_width)) {
        display: none !important;
    }
}

.askItMobile {
    position: absolute;
    right:    16px;
    top:      17px;
}

.dot {
    position: relative;

    &:before {
        content:          '';
        position:         absolute;
        right:            -2px;
        top:              -12px;
        background-color: #FD3C30;
        width:            8px;
        height:           8px;
        border-radius:    50%;
    }
}
