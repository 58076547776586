@import "../../../skin/css/sg/variables/variables_less";
@import "../../styles/common_less.module.less";
@import "../../../skin/css/sg/mixins_less";
@import "../CloseBlock/CloseBlock.module.less";

.popup {
    border-radius:    15px;
    box-sizing:       border-box;
    position:         fixed;
    top:              21%;
    left:             0;
    right:            0;
    bottom:           inherit;
    width:            100%;
    max-width:        323px;
    height:           auto;
    background-color: #ffffff;
    z-index:          100;
    box-shadow:       0 4px 12px 1px @gray1_15;
    padding:          32px 40px 40px;
    margin:           auto;

    @media only screen and (max-width: (@mobile)) {
        width:   calc(~'100% - 32px'); /*для отступа в 16px*/
        padding: 33px 28px 40px;
    }

    &Overlay {
        flex-grow:  1;
        z-index:    99;
        position:   fixed;
        top:        0;
        left:       0;
        right:      0;
        height:     100vh;
        background: rgba(0, 0, 0, .4);
        display:    none;
        animation:  fadeIn .2s ease-in;
    }

    &Open {
        display:    block;
        overflow-y: auto;
    }

    .overlayOpacity {
        background: transparent;
        opacity:    0;
    }

    .popupInput {
        box-sizing:       border-box;
        transition:       all .2s ease-in-out;
        color:            @gray1;
        font-size:        14px;
        width:            100%;
        height:           36px;
        line-height:      1;
        border:           1px solid @gray6;
        background-color: #ffffff;
        padding:          10px;
        margin:           0 0 20px 0;

        &:focus {
            border: 1px solid @primary;
        }
    }

    .popupText {
        color:         @gray1;
        font-size:     14px;
        font-weight:   600;
        margin-bottom: 20px;

        &OnlyText {
            margin-bottom: 0;
        }

        &Light {
            font-weight: 400;
        }
    }

    &Wide {
        top:        10%;
        width:      90%;
        max-height: 710px;
        height:     88%;
        max-width:  848px;
        padding:    3rem 2.5rem 8rem 3.5rem;

        @media only screen and (max-width: (@mobile)) {
            padding: 1.8rem 1.5rem 4rem 2.2rem;
        }
        @media only screen and (max-width: (@mobile)) {
            max-height: 646px;
            height:     70%;
        }

        .btnBlockAlone {
            background-color: #ffffff;
            position:         absolute;
            height:           50px;
            width:            100%;
            margin:           0;
            bottom:           38px;
            padding:          30px 0;
            left:             0;

            @media only screen and (max-width: (@mobile)) {
                bottom: 23px;
            }
        }
    }

    &Center {
        text-align: center;
    }

    &Icon {
        max-width: 600px;
        top:       15%;
        padding:   40px;

        .popupTitle {
            padding: 0 50px;
        }

        .popupTopIcon {
            width:  212px;
            height: 190px;
            margin: 0 auto;
        }

        @media only screen and (max-width: (@tablet)) {
            top:        0;
            padding:    24px;
            max-width:  none;
            background: rgba(255, 255, 255, 0.98);
            width:      100%;
            position:   fixed;
            overflow-x: hidden;
            overflow-y: scroll;
            height:     100vh;

            .popupTitle {
                font-size: 20px;
                padding:   0;
            }

            .popupTopIcon {
                width:  200px;
                height: 180px;
            }
        }
    }

    &Medium {
        //padding:   40px 30px 55px;
        max-width: 630px;
        top:       20%;

        @media only screen and (max-width: (@tablet)) {
            top:       10%;
            padding:   35px 25px 45px;
            max-width: 90%;
        }
        @media only screen and (max-width: (@mobile)) {
            width: calc(~'100% - 32px'); /*для отступа в 16px*/
            width: -moz-calc(~'100% - 32px');
            width: -webkit-calc(~'100% - 32px');
            width: -o-calc(~'100% - 32px');
        }
    }
}

.popupCloseBlock {
    top:             8px;
    right:           8px;
    font-size:       12px;
    height:          37px;
    width:           37px;
    display:         none;
    justify-content: center;
    align-items:     center;
    cursor:          pointer;
    border-radius:   50%;

    &:hover {
        background-color: #f5f5f5;
    }

    &Show {
        display: flex;
    }
    svg {
        fill: @gray5;
    }
}

/* Canceled Subscription Popup BEGIN--- */
.canceledSubscriptionPopup {
    max-width:     600px;
    box-shadow:    0 0 40px rgba(0, 0, 0, 0.08);
    border-radius: 15px;
    text-align:    center;
    padding:       40px 40px 40px;


    @media only screen and (max-width: (@big_mobile)) {
        width: calc(100% - 32px);
    }
    @media only screen and (max-width: (@mobile)) {
        border-radius: 9px;
        padding:       20px 28px 30px;
    }

    .popupSubscriptionIcon {
        padding: 4px;
    }

    .popupTitle {
        font-size:      24px;
        font-weight:    600;
        line-height:    1.5;
        letter-spacing: 0.2px;
        margin:         14px 0 0;
    }

    .popupText {
        font-size:      16px;
        line-height:    1.5;
        font-weight:    400;
        letter-spacing: 0.3px;
        padding-top:    24px;

        @media only screen and (max-width: (@mobile)) {
            padding-top: 16px;
        }
    }

    .popupBtn {
        height:        48px;
        border-radius: 3px;
        line-height:   48px;
        font-size:     16px;
        padding:       0 30px;
        margin-top:    20px;

        @media only screen and (max-width: (@mobile)) {
            width:       100%;
            max-width:   320px;
            font-weight: 600;
            margin-top:  5px;
        }
    }

    .popupBottomText {
        font-size:      13px;
        letter-spacing: 0.2px;
        padding-top:    24px;

        @media only screen and (max-width: (@mobile)) {
            font-size:      16px;
            line-height:    1.5;
            letter-spacing: 0.3px;
        }
    }
}

.discoverOverlay {
    border-radius: 0;
    top:           0;
    width:         100%;
    height:        100%;
    text-align:    center;
    padding:       9% 16px 100px;
    max-width:     100%;
}

/* ---Canceled Subscription Popup END */
