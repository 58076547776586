@import "../../../../styles/common_less.module.less";
@import '../../../../../skin/css/sg/variables/variables_less';

.AskAiBanner {
    width:           100%;
    height:          122px;
    display:         none;
    position:        relative;
    top:             70px;
    z-index:         11;
    background:      url('/skin/img/askIt/Askbanner.svg') no-repeat;
    background-size: cover;

    @media only screen and (max-width: (@big_width)) {
        top: 60px;
    }

    @media only screen and (max-width: (@tablet)) {
        height: 70px;
    }

    &.showAskAiBanner {
        display: block;
    }

    .container1920 {
        display:     flex;
        align-items: center;
        padding:     16px 120px;

        @media only screen and (max-width: (@tablet)) {
            padding: 16px;
        }
    }

    &TextWrap {
        display:        flex;
        flex-direction: column;
        margin-left:    12px;

        @media only screen and (max-width: (@tablet)) {
            margin-left: 4px;
        }
    }

    &Title {
        font-size:      24px;
        font-style:     normal;
        font-weight:    600;
        line-height:    36px;
        letter-spacing: 0.072px;

        @media only screen and (max-width: (@tablet)) {
            font-size:      16px;
            line-height:    24px;
            letter-spacing: 0.048px;
        }
    }

    &Icon {
        @media only screen and (max-width: (@tablet)) {
            width:  40px;
            height: 40px;
        }
    }

    &Text {
        font-size:      14px;
        font-style:     normal;
        font-weight:    400;
        line-height:    20px;
        letter-spacing: 0.042px;

        @media only screen and (max-width: (@tablet)) {
            display: none;
        }
    }
}

.askItBtn {
    background-color: #fff;
    margin-left:      auto !important;

    &Text {
        font-size:      16px;
        font-style:     normal;
        font-weight:    600;
        letter-spacing: 0.048px;

        @media only screen and (max-width: (@tablet)) {
            font-size: 14px;
        }
    }

    &Arrow {
        width:         12px;
        height:        12px;
        fill:          @green;
        margin-left:   10px;
        margin-bottom: -1px;
    }
}